import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom'
import { Card, Button } from 'react-bootstrap';
import { FaLeaf } from 'react-icons/fa'

const ProductDetail = () => {
  const { state } = useLocation();
  const { prodId } = state;
  const selectedProduct = useSelector(state =>
    state.products.availableProducts.find(prod => prod.id == prodId));

  const { id, title, description, price, qta, unit, cool, imageUrl } = selectedProduct;

  return (
    <div className='container'>
      <div className='card p-4 '>
        <div className='row rows-col-2'>
          <div className='col'>
            <Card style={{ width: '100%', height:'70vh'}}> 
              <Card.Img variant="top" src={imageUrl} className='cover-fit' />
              <Card.Body>
                <Card.Title>{description} </Card.Title>
                <Card.Subtitle className='my-1'> {price} € / {unit} </Card.Subtitle>
              
                {cool &&
                  <Card.Text className='text-primary float-end' > Prodotto Fresco <FaLeaf /></Card.Text>}
              </Card.Body>
            </Card>
          </div>
          <div className='col'>
            <Card >
             
              <Card.Body>
                <Card.Title>{title}</Card.Title>
                <Card.Text>
                  {description}
                </Card.Text>
                <Button variant="secondary">Aggiungi al Carrello </Button>
              </Card.Body>
            </Card>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProductDetail;