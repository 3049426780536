import { OverlayTrigger, Popover } from 'react-bootstrap';
import { FaLeaf } from 'react-icons/fa'


const ProductCardover = props => {
  
  const { itemData: { id, title, description, price, unit, cool }, style } = props;

  return (

    <div className="col g-0" >
      <OverlayTrigger
        placement='bottom'
        key={id}
        delay={{ show: 250, hide: 400 }}
        overlay={<Popover id={id} className={`${style} bg-white`}>
          <Popover.Header as="h6">{title}</Popover.Header>
          <Popover.Body className='mb-3'>
            <label className='text-secondary mb-1'>{description}</label>
            <label>{price} € / {unit}</label>
            {cool &&
              <span className='text-primary float-end' > Prodotto Fresco <FaLeaf /></span>}

          </Popover.Body>
        </Popover>}
      >
        {props.children}
      </OverlayTrigger>
    </div>

  );
};

export default ProductCardover;