import Header from './Header';
import Footer from './Footer';

const Layout = (props) => {
  return (
    <div className='App'>
      <Header  />
      <main className='container-fluid my-5'>{props.children}</main>
      <Footer />
    </div>
  );
};

export default Layout;