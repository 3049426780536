import { Container, Card, Image, Row, Col } from 'react-bootstrap';
import MailTo from '../components/UI/MailTo';
import ReactWhatsapp from "react-whatsapp";

const Contacts = () => {
  return (

    <Container>
      <Row>
        <Col className='text-center align-self-center'>
          <Card className='my-2 bg-transparent border-0' >
            <Card.Body >
              <Image src='/logo512.png' style={{ width: '6rem' }} className='mb-3' />
              <Card.Title className='text-secondary fw-bold mb-5' as={'h2'}>
                I Prodotti dell'Orto di Paolo
              </Card.Title>
              <Card.Text className='mt-2 mb-2' as={'h4'}>  Via dei Meli 199 </Card.Text>
              <Card.Text className='mt-2 mb-5' as={'h4'}>  63100 Ascoli Piceno (AP) </Card.Text>
              
              <Card.Text className='fw-bold mt-4 mb-3' as={'h4'}>
                <ReactWhatsapp number="+393920703098"
                  message="Salve "
                  className='btn-whatsapp' >telefono: 3920703098 </ReactWhatsapp>
              </Card.Text>
              <Card.Text className='mb-5' as={'h4'}>
                <MailTo email="ortodipaolo@pagefha.com" subject="Richiesta Informazioni su i Prodotti dell'Orto di Paolo" body="Richiesta Informazioni">ortodipaolo@pagefha.com </MailTo>
              </Card.Text>

            </Card.Body>

          </Card>
        </Col>
      </Row>
    </Container>

  );
};
export default Contacts;
