import React, { Suspense } from 'react';

import { Route, Routes, Navigate } from 'react-router-dom'; //Navigate: Redirect
import { createStore, combineReducers, applyMiddleware } from 'redux';
import { Provider } from 'react-redux'

import ReduxThunk from 'redux-thunk';
import productsReducer from './store/reducer/products';
import Loading from './components/UI/Loading';

import Home from './containers/Home';
import ProductsOverview from './containers/shop/ProductOverview'
import ProductDetail from './containers/shop/ProductDetail'
import WhoWeAre from './containers/WhoWeAre';
import Contacts from './containers/Contacts';
import NotFound from './containers/NotFound';
import Layout from './components/layout/Layout';

const rootReducer = combineReducers({
  products: productsReducer,
});

const store = createStore(rootReducer, applyMiddleware(ReduxThunk));


function App() {
  const routes = (
    <Routes>
      <Route path='/' exact  >
        <Route index element={<ProductsOverview />} />
        <Route path='/prodotti/:single' element={<ProductDetail/>} />
        <Route path='/chisiamo' element={<WhoWeAre />} />
        <Route path='/contatti' element={<Contacts />}/>
        <Route path='*' element={<NotFound />} />
      </Route>
    </Routes>
        );
        

  return (
    <Provider store={store} >
      <Layout>
        <Suspense fallback={<Loading variant='success'></Loading>}>
          {routes}
        </Suspense>
      </Layout> 
    </Provider>
    
  );
}

export default App;