
import React from "react"

const Loading = props => {

  const color = 'text-' + (props.color ? props.color : 'primary');
  return (
    <div className="d-flex justify-content-center">
      <div className={`spinner-grow ${color}`} role="status">
        <span className="visually-hidden">Loading...</span>
      </div>
    </div>
  );
}

export default Loading;