import React, {useState, useEffect, useCallback} from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import ProductItem from '../../components/Product/ProductItem';
import Loading from '../../components/UI/Loading';
import * as prodActions from '../../store/actions/products'

const ProductsOverview = props => {
  const products = useSelector(state => state.products.availableProducts);

  const [isLoading, setIsLoading] = useState(false);
  const [isRefreshing, setIsRefreshing] = useState(false);
  const [error, setError] = useState();
  const dispatch = useDispatch();

  const navigate = useNavigate();
 
  //--- loadProaducts ------------------------------ 
  const loadProaducts = useCallback(async () => {
    setError(null);
    setIsLoading(true);
    setIsRefreshing(true);
    try {
      await dispatch( prodActions.fetchProducts() );
    }
    catch(err){
      setError(err.message);
    }
    finally {
      setIsLoading(false);
      setIsRefreshing(false);
    }
  }, [dispatch]);


  /* -------- useEffect when Componendt Did Mount ------- */
  useEffect(() => {
    loadProaducts();
  },[loadProaducts] );

  /* -------- useEffect Error ------- */
  useEffect(()=>{
    if (error) {
      
      alert('Si è verificato un errore: ', error.message, [{text:'Ok'}]);
    }
  },[error]);

  /**----------------- Delete -----------------**/
  const deleteHandler = (id) =>{
    alert('Sei Sicuro', 'Vuoi cancellare davvero questo prodotto?',[
    { text:'No', style:'default'},
    { text:'Si', style:'destructive', 
        onPress: async()=>{
          setError(null);
          try{
            await dispatch(prodActions.deleteProduct(id));
          }
          catch (err) {
            setError( err );
          }
        }}]
    );
  };

  /**----------------- Select -----------------**/
  const selectItemHandler = (id, title) => {
    
   
  }
  
  /**----------------- OnSelect -----------------**/
  const onSelectHandler = itemData => {
    console.log( itemData )
    navigate('/prodotti/dettaglio', { state: { prodId: itemData.id } });
    //navigate(`/${itemData.id}`);
  }

  /**----------------- OnAction -----------------**/
  const onActionHandler = itemData =>{
    
  }


  /*------------- default Spinner: isLoading ------------------- */
  let Content =  
    <div >
      <Loading color="secondary"/>
   </div>;
  
  if (!isLoading) {
    if (products.length === 0) {
      Content = 
        <div>
          Inserisci nuovi prodotti! 
        </div>
    } else {
      Content = (
        <div className="row rows-col-4" >
            {products.map(item => <ProductItem itemData={item} key={item.key} onSelect={()=>onSelectHandler(item)} />)}
        </div>  
      )
            
    }
  }

  return (
    <div className='container-md'>
      {Content}
    </div>
  );
};


export default React.memo( ProductsOverview );
