import { Container, Card, Image, Row, Col} from 'react-bootstrap';

const WhoWeAre = () => {
  
  return (
    <Container>
      <Row>
      
        <Col className='text-center align-self-center'>
          <Card className='my-2 bg-transparent border-0' >
              <Card.Body >
                <Image src='/logo512.png' style={{ width: '6rem' }} className='mb-2' />
                <Card.Title className='text-secondary fw-bold mb-3' as={'h2'}>
                  I Prodotti dell'Orto di Paolo
                </Card.Title>
                <Card.Text className='mt-4' as={'h5'}> Progetto sviluppato e realizzato dalla </Card.Text>
                <Card.Text as={'h5'}> Coopearativa Sociale  </Card.Text>
                <Card.Text className='fw-bold mt-2 mb-5' as={'h4'}> P.Ge.F.Ha Onlus </Card.Text>
                <Card.Text as={'h5'}> in collaborazione con </Card.Text>
                <Card.Text className='fw-bold mt-2 mb-5' as={'h4'}> AgriAbilità Coop. Sociale </Card.Text>
                <Card.Text as={'h5'}> e insieme alla </Card.Text>
                <Card.Subtitle className='fw-bold mt-2' as={'h4'} >
                  Fondazione Cassa di Risparmio 
                </Card.Subtitle>
                <Card.Text className='fw-bold mb-2' as={'h5'} > di Ascoli Piceno</Card.Text>
                  <Image src='/assets/fondazione-logo.png' style={{ width: '14rem' }} className='mx-3' />
              </Card.Body>
             
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default WhoWeAre;