import { Link } from 'react-router-dom';
import { Container, Navbar, Nav, Offcanvas, Form, FormControl, Button } from 'react-bootstrap'
import ImgLogo from './ImgLogo';

const Header = (props) => {
  return (
    <header className={props.classHeader}>
     
      {/* <Navbar bg="light" variant="light" expand="md" >
        <Container fluid>
          <Navbar.Brand as={Link} to="/" className='m-auto p-4'>
            <img
              src="/logo512.png"
              width="80"
              className="d-inline-block align-top"
              alt="L'orto di Paolo"
            />
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="navbarScroll" />
          <Navbar.Collapse id="navbarScroll">
            <Nav
              className="me-auto my-2 my-lg-0"
              style={{ maxHeight: '200px' }}
              navbarScroll
            >
              <Nav.Link as={Link} to="/" >L'Orto di paolo</Nav.Link>
              <Nav.Link as={Link} to="/chisiamo">Chi siamo</Nav.Link>
              <Nav.Link as={Link} to="/contatti">Contatti</Nav.Link>
  
            </Nav>
            
          </Navbar.Collapse>
        </Container>
      </Navbar> */}

      
      <Navbar bg="light" expand={false}>
        <Container fluid className='ms-4'>
          <Navbar.Toggle aria-controls="offcanvasNavbar" />
          <Navbar.Brand as={Link} to="/" className='m-auto ms-5'>
            <ImgLogo />
          </Navbar.Brand>
          <Form className="d-flex me-5">
            <FormControl
              type="search"
              placeholder="Search"
              className="me-2"
              aria-label="Search"
            />
            <Button variant="outline-success">Search</Button>
          </Form>
          <Navbar.Offcanvas
            id="offcanvasNavbar"
            aria-labelledby="offcanvasNavbarLabel"
            placement="start"
          >
            <Offcanvas.Header closeButton>
              <Offcanvas.Title id="offcanvasNavbarLabel" className='mx-auto'>
                <Link to='/'>
                  <ImgLogo  />

                </Link> </Offcanvas.Title>
            </Offcanvas.Header>
            <Offcanvas.Body>
              <Nav className="justify-content-end flex-grow-1 pe-3 fs-5">
                <Nav.Link as={Link} to="/chisiamo">Chi siamo</Nav.Link>
                <Nav.Link as={Link} to="/contatti">Contatti</Nav.Link>
              </Nav>
             
            </Offcanvas.Body>
          </Navbar.Offcanvas>

        </Container>
      </Navbar>




      
    </header>
  );
};

export default Header;