import React, { Component } from "react";

class ImgLogo extends Component{

  render() {
    return (
      <img
        //src="/logo512.png"
        src="/assets/logo-splash.png"
        width={this.props.width ? this.props.width : 200}
        className="d-inline-block align-top"
        alt="L'orto di Paolo"
      />
    );
  }
}

export default ImgLogo;