import React from "react";
import { Link } from "react-router-dom";


const MailTo = ({ email, subject = '', body = '', children }) => {
  let params = subject || body ? '?' : '';
  if (subject) params += `subject=${encodeURIComponent(subject)}`;
  if (body) params += `${subject ? '&' : ''}body=${encodeURIComponent(body)}`;
  
  return (
    <Link
      className="mailto"
      to='#'
      onClick={(e) => {
        window.location.href = `mailto:${email}${params}`;
        e.preventDefault();
      }}
    >
      {children}
    </Link>
  );
};

export default MailTo;
