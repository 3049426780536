import React from "react";
import { Link } from 'react-router-dom';
import { Container, Navbar, Nav } from 'react-bootstrap';

const Footer = props => {
  return (


    <footer className={`${props.classFooter} pt-4` }>
      <div className="container-fluid text-center text-md-left">
        <div className="row my-5 rows-col-4">

          <div className="col ">
            <img
              src="/logo512.png"
              width="10%"
              className="d-inline-block flot-end"
              alt="L'orto di Paolo"
            />
          
            <h5 className="text-warning fw-bold my-4"> I Prodotti di L'Orto di Paolo</h5>
            <h6>P.A.GE.F.HA Onlus</h6>
            <h6 className="mb-4">in Collaborazione con Agriabilità Cooperativa Sociale</h6>

          </div>
  
          <div className="col">
            <a href="http://www.fondazionecarisap.it/" target='_blank' rel="noreferrer">
              <img
                src="/assets/fondazione-logo.png"
                width="200"
                className="d-inline-block flot-end"
                alt="Fondazione Carisap"
              />
            </a>

            <h5 className="text-warning fw-bold my-4"> Fondazione Carisap </h5>
            <h6>Iniziativa Realizzata in Collaborazione con</h6>
            <h6 className="mb-4"> la Fondazione Carisap di Ascoli Piceno</h6>

          </div>

  
          <div className="col">
            <h5 className="text-uppercase"> </h5>

            
              <Container fluid>
                <Nav.Link as={Link} to="/" >L'Orto di paolo</Nav.Link>
                <Nav.Link as={Link} to="/chisiamo">Chi siamo</Nav.Link>
                <Nav.Link as={Link} to="/contatti">Contatti</Nav.Link>
              </Container>
            

          </div>

        </div>
      </div>
  
      <div className="footer-copyright text-end py-3 px-4 ">
        
        <div className="container-fluid text-center text-md-left">
          <div className="row">
            <div className="col-md-4">
            </div>
            <div className="col-md-4">
              <a href="/">Privacy Policy </a>
            </div>
            <div className="col-md-4">
              Copyright © <b> L'Orto di paolo 2021</b> 
            </div>
          </div>
        </div>       
      </div>
  
    </footer>

  );
}

export default Footer