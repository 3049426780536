import React from 'react';
import { useDispatch } from 'react-redux';

import { Card } from 'react-bootstrap';
import ProductCardover from '../UI/ProductCardover'
import { FaLeaf } from 'react-icons/fa'
import classes from './ProductItem.module.css';


const ProductItem = props => {
  //const dispatch = useDispatch();

  // const toggleFavHandler = () => {
  //   dispatch(toggleFav(props.id));
  // };


  const { itemData: { title, cool, imageUrl } } = props;
  
  return (
    <div className="col g-0" >
      <ProductCardover itemData ={props.itemData} style={classes.popover} >
        <Card className='my-2' style={{ width: '20rem', height: '20rem', cursor: 'pointer' }} onClick={props.onSelect}>
            <Card.Img variant="top" src={imageUrl} className={classes.cover} />
            <Card.Body >
              <div className='fs-6 fw-bold my-1'>{title}</div>
              {cool &&  <Card.Text className='text-primary float-end fs-6' >
                          Fresco <FaLeaf />
                        </Card.Text>}
            </Card.Body>
        </Card>
      </ProductCardover>
    </div>
    
  );
};

export default ProductItem;